import React from 'react';
import './Header.scss';
import Navbar from '../Navbar/Navbar';

import { ReactComponent as SVGLogo } from './svg/visys-logo.svg';

const Header = () => {
  return (
    <div className='header'>
        <div className='header__content'>
            <a className='header__content-logo' href='#'>
                <SVGLogo/>
            </a>
            <div className='header__content-navbar'>
              <Navbar/>
            </div>
            <div className='header__content-button'>
              <button>Связаться</button>
            </div>
        </div>
    </div>
  )
}

export default Header;