import React, { useState, useEffect } from 'react';
import './TextAnimation.scss';

function RenderSubtitle() {
  const [subtitle, setSubtitle] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const phrases = [
    'контроль протяженных территорий',
    'увеличение изображения в архиве без потери качества',
    'высокое разрешение по всему полю наблюдения',
    'неограниченное количество пользователей'
  ];

  useEffect(() => {
    const typeSpeed = isDeleting ? 50 : 50;
    const deletePauseTime = 2500;
    const newPhrasePauseTime = 1500;

    const handleTyping = () => {
      const currentPhrase = phrases[currentPhraseIndex];

      if (!isDeleting && currentIndex < currentPhrase.length) {

        setSubtitle((prev) => prev + currentPhrase[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      } else if (isDeleting && currentIndex > 0) {
        setSubtitle((prev) => prev.slice(0, -1));
        setCurrentIndex((prev) => prev - 1);
      } else if (!isDeleting && currentIndex === currentPhrase.length) {
        setIsPaused(true);
        setTimeout(() => setIsPaused(false), deletePauseTime);
        setIsDeleting(true);
      } else if (isDeleting && currentIndex === 0) {
        setIsPaused(true);
        setTimeout(() => {
          setIsPaused(false);
          setIsDeleting(false);
          setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
        }, newPhrasePauseTime);
      }
    };

    if (!isPaused) {
      const timer = setTimeout(handleTyping, typeSpeed);
      return () => clearTimeout(timer);
    }
  }, [subtitle, currentIndex, isDeleting, currentPhraseIndex, isPaused]);

  return (
    <h2 className="title">
      Polycamera
      <br />
      {subtitle}
      <span className="cursor">|</span>
    </h2>
  );
}

export default RenderSubtitle;
