import React, { useState } from 'react';
import './Navbar.scss';
import { Link } from 'react-scroll';

import { ReactComponent as SVGChevron } from './svg/chevron-down.svg';

const Navbar: React.FC = () => {
    const [openMenu, setOpenMenu] = useState<string | null>(null);

    const toggleMenu = (menu: string) => {
        setOpenMenu(openMenu === menu ? null : menu);
    };

    const closeMenu = () => {
        setOpenMenu(null);
    };

    return (
        <nav className="navbar">
            <ul className="navbar__list">
                <li
                    className={`navbar__item ${openMenu === 'products' ? 'open' : ''}`}
                    onClick={() => toggleMenu('products')}
                >
                    <span>Продукты<SVGChevron /></span>
                    <ul className="dropdown-menu">
                        <li><Link to="policamera" smooth={true} duration={500} onClick={closeMenu}>Polycamera X8</Link></li>
                        <li><Link to="norma" smooth={true} duration={500} offset={-270} onClick={closeMenu}>Видеодекодер Norma S1</Link></li>
                        <li><Link to="canopus" smooth={true} duration={500} offset={-50} onClick={closeMenu}>Регистратор Canopus NX</Link></li>
                        <li><Link to="omniclient" smooth={true} duration={500} offset={-100} onClick={closeMenu}>Видеоплатформа Omni Client</Link></li>
                        <li><Link to="#" smooth={true} duration={500} onClick={closeMenu}>Видеостена Videowall</Link></li>
                    </ul>
                </li>

                <li
                    className={`navbar__item ${openMenu === 'development' ? 'open' : ''}`}
                    onClick={() => toggleMenu('development')}
                >
                    <span>Разработка<SVGChevron /></span>
                    <ul className="dropdown-menu">
                        <li><a href="#" onClick={closeMenu}>Разработка полного цикла</a></li>
                        <li><a href="#" onClick={closeMenu}>Разработка веб-приложений</a></li>
                        <li><a href="#" onClick={closeMenu}>Frontend-разработка</a></li>
                        <li><a href="#" onClick={closeMenu}>Backend-разработка</a></li>
                        <li><a href="#" onClick={closeMenu}>Аппаратные решения</a></li>
                        <li><a href="#" onClick={closeMenu}>Аудит процессов разработки</a></li>
                    </ul>
                </li>

                <li className="navbar__item">
                    <span>Компетенции</span>
                </li>

                <li
                    className={`navbar__item ${openMenu === 'aboutCompany' ? 'open' : ''}`}
                    onClick={() => toggleMenu('aboutCompany')}
                >
                    <span>О компании<SVGChevron /></span>
                    <ul className="dropdown-menu">
                        <li><a href="#" onClick={closeMenu}>О нас</a></li>
                        <li><a href="#" onClick={closeMenu}>Стажировка</a></li>
                        <li><a href="#" onClick={closeMenu}>Контакты</a></li>
                    </ul>
                </li>

                <li className="navbar__item">
                    <span>Контакты</span>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
